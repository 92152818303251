.wrapper {
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	text-align: left;
  transition: box-shadow .34s;

	&:hover {
		box-shadow: 0px 4px 35px 0px #0000000f;
	}

	td {
		position: relative;
		z-index: 0;
		padding: 18px 20px 18px 10px;
		border-bottom: 1px solid #e9ecff;

		&:first-of-type {
			padding: 18px 20px;
		}
	}
}
