$primary-color: #005179;
$secondary-color: #00BBEE;
$link-color: #1176FF;
$light-color: #fff;
$dark-color: #252B42;
$text-color: #252B42;
$gray-color: #FBFBFB;
$success-color: #1fc198;
$danger-color: #E83A38;
$yellow-color: #F0DA69;

$font-size: (
	xs: 14px,
	sm: 16px,
	md: 18px,
	lg: 22px,
	xl: 26px,
	xxl: 30px,
	xxxl: 32px,
);

$xs: 414px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
$duration: 0.25s;
