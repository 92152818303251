@use '@styles/variables' as *;

.ratio {
	display: flex;
	gap: 15px;
	align-items: center;
	margin-bottom: 20px;

	&Label {
		font-size: 16px;
	}

	&Max {
		margin-top: 24px;
	}
}

.input {
	width: 100%;
	border: 1px solid #d3d3d3;
	background: #fff;
	padding: 18px 10px 18px 22px;
	color: $dark-color;
	font-size: 16px;
	font-weight: 400;
	outline: transparent;
}

.button {
	display: block;
	text-align: center;
	background-color: transparent;
	color: $primary-color;
	min-width: 26px;
	width: 26px;
	height: 26px;
	border: 1px solid $primary-color;
	border-radius: 50%;

	text-decoration: none;
	font-size: 20px;
	cursor: pointer;
}

.field {
	max-width: 149px;
}
