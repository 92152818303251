@use '@styles/variables' as *;

.wrapper {
	margin-top: 36px;

	:global {
		.container {
			display: flex;
			flex-wrap: wrap;
			gap: 24px 51px;
			width: 100%;

			> div {
				width: 100%;
				flex: 1 0 318px;
				background-color: $light-color;

				@media screen and (min-width: $md) {
					border-radius: 12px;
				}
			}
		}
	}

	.faq {
		padding-top: 25px;
		padding-bottom: 25px;
	}
}
