@use '@styles/variables' as *;

.wrapper {
	display: flex;
  flex-direction: column;
  gap: 10px 20px;

	@media screen and (min-width: $xl) {
		gap: 10px 40px;
	}
}


.inline {
	display: flex;
	gap: 17px;
}
