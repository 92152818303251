@use '@styles/variables' as *;

.input {
	&Label {
		&Text {
			display: block;
			color: $dark-color;
			font-size: 16px;
			font-weight: 400;
			line-height: 150%;
			margin-bottom: 10px;
		}
	}

	&Field {
		width: 100%;
		border: 1px solid #d3d3d3;
		background: #fff;
		padding: 18px 10px 18px 22px;
		color: $dark-color;
		font-size: 16px;
		font-weight: 400;
		outline: transparent;

		&:disabled{
			opacity: .5;
		}
	}

	&Error {
		display: block;
		margin-top: 5px;
		margin-bottom: 10px;
		color: $danger-color;
		font-size: 12px;
		font-weight: 400;
		min-height: 15px;
		opacity: 0;
		transition: opacity 0.24s;
	}

	&[data-status='error'] {
		.inputField {
			border: 1px solid $danger-color;
		}

		.inputError {
			opacity: 1;
		}
	}

	&[data-status='success'] {
		.inputField {
			border: 1px solid $success-color;
		}
	}
}

textarea.inputField {
	min-height: 146px;
	resize: vertical;
}
