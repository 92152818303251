@use '@styles/variables' as *;

.wrapper {
	width: 100%;

	.item {
		text-align: left;
		gap: 7px;
		font-size: 16px;
		font-weight: 500;
		border-bottom: 1px solid #e9ecff;

		&Text {
			padding: 18px 20px 18px 10px;
			display: flex;
			align-items: center;
			gap: 8px;
		}

		&:first-of-type {
			.itemText {
				padding: 18px 20px;
			}
		}
	}

	.sort {
		display: none;
		// display: inline-flex;
		flex-direction: column;
		gap: 2px;

		&Button {
			display: flex;
			width: max-content;
			cursor: pointer;
			background-color: transparent;

			svg {
				path {
					transition: fill 0.34s;
				}
			}

			&:hover,
			&[data-selected='true'] {
				svg {
					path {
						fill: #3e4954;
					}
				}
			}

			&:first-of-type {
				align-items: flex-end;
				padding: 5px 5px 0;
				svg {
					transform: rotate(180deg);
				}
			}

			&:last-of-type {
				align-items: flex-start;
				padding: 0 5px 5px;
			}
		}
	}
}
