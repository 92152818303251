@use '@styles/variables' as *;
@use '@styles/mixins' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: $light-color;
  border: 1px solid rgba(225, 225, 225, 1);

  .header{
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 16px 20px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }

  .list{
    padding: 20px;
    overflow-y: auto;
    @include scrollbar;
  }
}
