@use '@styles/variables' as *;

.lists {
	display: flex;
	flex-direction: column;
	gap: 20px 30px;
	margin: 40px 0;
	min-height: 300px;
  max-height: 70vh;

	@media screen and (min-width: $md) {
		padding: 35px 20px;
		background-color: $light-color;
	}

	@media screen and (min-width: $lg) {
		flex-direction: row;

		> * {
			flex: 1;
		}
	}

	@media screen and (min-width: $xl) {
		padding: 35px 50px;
	}
}

.buttons{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 14px;
}
