@use '@styles/variables' as *;

.wrapper {
	display: flex;
	flex-direction: column;
	gap: 40px;
	margin-top: 25px;
	padding-bottom: 160px;

	@media screen and (min-width: $md) {
		.submit {
			width: 100%;
			max-width: 278px;
			margin-left: auto;
		}
	}

	@media screen and (min-width: $lg) {
		margin-top: 40px;
		gap: 80px;
	}
}
