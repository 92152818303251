@use '@styles/variables' as *;

.wrapper {
	display: none;

	@media screen and (min-width: $md) {
		display: block;
		background-color: $gray-color;
		padding: 16px 0;

		:global {
			.container {
				display: flex;
				justify-content: flex-end;
				gap: 32px;
			}
		}

		.profile {
			display: flex;
			align-items: center;
			justify-content: center;

			&Image {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 46px;
				height: 46px;
				border-radius: 50%;
				overflow: hidden;
				background-color: $light-color;
				color: $light-color;
				font-weight: 700;
				font-size: 28px;
				border: 3px solid $light-color;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			span {
				font-size: 14px;
				font-weight: 600;
				line-height: 21px;
			}

			&Text {
				display: flex;
				align-items: center;
				height: 46px;
				padding: 0 40px 0 22px;
				margin-right: -20px;
				background-color: $primary-color;
				color: $light-color;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
			}
		}
	}
}
