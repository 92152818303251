@use '@styles/variables' as *;

.header {
	:global {
		.container {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			gap: 40px;
		}
	}

	&Button {
		width: 100%;
	}
}

.btn{
	margin-left: auto;
}

@media screen and (min-width: $sm) {
	.header {
		&Button {
			width: auto;
		}
	}
}
