@use '@styles/variables' as *;

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
	display: flex;
	flex-direction: column;
	gap: 43px;
	align-items: center;
	justify-content: center;

	.title {
		font-size: 20px;
		font-weight: 600;
		line-height: 30px;
		text-align: center;
	}

  .icon{
    width: 100%;
    max-width: 108px;
    overflow: hidden;

    svg{
      width: 100%;
      animation: rotate360 2s linear infinite;
    }
  }
}
