@use './variables' as *;

@mixin roboto {
	font-family: 'Roboto', sans-serif;
}

@mixin pseudo-element {
	content: '';
	position: absolute;
	display: block;
}

@mixin hide-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin scrollbar {
	&::-webkit-scrollbar {
		width: 7px;
		height: 7px;
	}
	
	&::-webkit-scrollbar-track {
		background: #e3e3e3;
		border-radius: 10px;
	}
	
	&::-webkit-scrollbar-thumb {
		background: $primary-color;
		border-radius: 10px;
	}
	
	&::-webkit-scrollbar-thumb:hover {
		background: darken($primary-color, 10%);
	}
}