@use '../../scss/variables' as *;

.wrapper {
	padding-left: 20px;
	padding-right: 20px;

	.title {
		display: block;
		width: calc(100% + 20px);
		margin-right: -20px;
		margin-bottom: 0;
		margin-left: -20px;
		padding-left: 20px;
		padding-bottom: 17px;
		font-size: 20px;
		font-weight: 500;
		line-height: 30px;
		color: $dark-color;
		border-bottom: 1px solid rgba(233, 236, 255, 1);
	}

	@media screen and (min-width: $md) {
		padding-left: 50px;
		padding-right: 40px;

		.title {
			width: calc(100% + 90px);
			margin-left: -50px;
			padding-left: 50px;
		}
	}
}
