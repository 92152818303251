.buttons{
  display: flex;
  justify-content: flex-end;
  margin-top: 31px;
  gap: 10px;
}

.metrics{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: flex-end;
  > *{
    flex: 1 0 156px;
  }

  &Price{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.hidden{
  margin-top: 24px;
}