@use '@styles/variables' as *;

.wrapper {
  position: relative;
	display: flex;
	align-items: center;
  height: 43px;
	min-height: max-content;
	gap: 22px;
  max-width: max-content;
	background-color: $light-color;
	font-size: 14px;
	font-weight: 400;
  margin-bottom: 10px;
  max-height: 700px;
	transition: background-color 0.34s;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; 
  cursor: grab;

	.image {
		min-width: 43px;
		width: 43px;
		height: 43px;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: center;
			pointer-events: none;
		}
	}

	&:active {
    background-color: rgba($dark-color, .05);
    cursor: grabbing;
	}
}
