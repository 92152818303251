@use '@styles/variables' as *;
@use '@styles/mixins' as *;

.wrapper {
	position: fixed;
	z-index: 999999;
	left: 0;
	width: 100%;
	top: 0;
	height: 100vh;
	background-color: rgba($dark-color, 0.4);

	.content {
		position: absolute;
		max-width: 500px;
		top: 50%;
		left: 50%;
		width: calc(100% - 30px);
		border-radius: 7px;
		background-color: #fff;
		padding: 40px 20px 30px;
		max-height: calc(100vh - 64px);
		overflow-x: hidden;
		overflow-y: auto;
		transform: translate(-50%, -50%);
		filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.14));
		@include scrollbar;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.close {
		display: block;
		padding: 10px;
		background-color: transparent;
		cursor: pointer;
		transform: translateY(-30px) translateX(20px);

		svg {
			width: 12px;
		}
	}

	.title {
		margin-bottom: 0;
	}

	@media screen and (min-width: $md) {
		.content {
			padding: 40px;
		}

		.close {
			transform: translateY(-30px) translateX(30px);
		}
	}
}
