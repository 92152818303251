@use '@styles/variables' as *;
@use '@styles/mixins' as *;

.navbar {
	position: fixed;
	left: 50%;
	top: 0;
	width: 100%;
	max-width: $xl;
	z-index: 999;
	transform: translateX(-50%);
	box-shadow: 28px 24px 35px 0px #00000005;

	.logo {
		img {
			display: block;
			width: 169px;
		}
	}

	:global(.container) {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 24px;
		padding-top: 12px;
		padding-bottom: 12px;
	}

	&:before {
		content: '';
		position: absolute;
		z-index: 0;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $light-color;
	}

	.mobile {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 24px;
	}

	&Button {
		position: relative;
		padding: 10px;
		width: 37px;
		height: 30px;
		background-color: transparent;
		cursor: pointer;

		span {
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			width: 25px;
			height: 2px;
			border-radius: 1px;
			background-color: $dark-color;
			transition: transform 0.24s;

			&:first-of-type {
				transform: translateX(-50%) translateY(5px);
			}
			&:last-of-type {
				transform: translateX(-50%) translateY(-5px);
			}
		}
	}

	&Menu {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: -2;
		transform: translateY(-100%);
		background-color: $light-color;
		width: 100%;
		max-height: calc(100vh - 70px);
		padding: 16px 0 16px;
		opacity: 0;
		transform-origin: top;
		overflow-y: auto;
		@include scrollbar;
		transition: transform 0.34s, opacity 0.34s;

		&Icon {
			display: flex;
			align-items: center;
			width: 45px;
		}

		&Current {
			display: block;
			width: 7px;
			height: 7px;
			margin-left: auto;
			border-radius: 1px;
			border-top: 2px solid #d3d6e4;
			border-right: 2px solid #d3d6e4;
			transform: rotate(45deg);
			opacity: 0;
			transition: opacity 0.34s;
		}

		&Item {
			position: relative;
			z-index: 0;
			display: flex;
			align-items: center;
			width: 100%;
			gap: 10px;
			padding: 21px 21px 21px 40px;
			border-radius: 8px;
			color: $primary-color;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			text-decoration: none;
			letter-spacing: 0.16px;
			background-color: transparent;
			cursor: pointer;
			transition: color 0.24s, background-color 0.24s;

			svg {
				:global {
					.on-hover {
						transition: fill 0.24s;
					}
				}
			}

			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
				display: block;
				width: 7px;
				height: 100%;
				background-color: $primary-color;
				opacity: 0;
				transform: scaleY(0);
				transition: opacity 0.34s, transform 0.34s;
				transform-origin: top;
			}

			&::after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				background-color: $light-color;
				box-shadow: 0px 7px 15px 0px #00517908;
				opacity: 0;
				transition: opacity 0.34s;
			}

			&:hover,
			&[data-is-current='true'] {
				&:before {
					opacity: 1;
					transform: scaleY(1);
				}
				&:after {
					opacity: 1;
				}
			}

			&[data-is-current='true'] {
				.navbarMenuCurrent {
					opacity: 1;
				}
			}
		}
	}

	&Expanded {
		.navbarMenu {
			transform: translateY(0);
		}

		.navbarMenu {
			opacity: 1;
		}

		.navbarButton {
			span {
				&:first-of-type {
					transform: translateX(-50%) translateY(0) rotate(45deg);
				}
				&:last-of-type {
					transform: translateX(-50%) translateY(0) rotate(-45deg);
				}
			}
		}
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		border-radius: 20px;
		box-shadow: 0 0 14px rgba(70, 80, 90, 0.2);
		opacity: 0;
		transition: opacity 0.5s;
	}

	.utils {
		margin-top: 20px;
		padding-top: 20px;
		border-top: 1px solid #ececee;

		a,
		button {
			color: #b4b4b4;
		}
	}

	@media screen and (min-width: $md) {
		left: 0;
		transform: translateX(0);
		width: clamp(250px, 25vw, 345px);
		max-width: 345px;
		height: 100vh;

		.logo {
			width: 80%;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			max-width: 218px;

			img {
				width: 100%;
			}
		}

		:global {
			.container {
				gap: 54px;
				height: 100%;
				flex-direction: column;
				align-items: flex-start;
				padding: 36px 0 10px;
			}
		}

		.mobile {
			display: none;
		}

		&Button {
			display: none;
		}

		&Menu {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			z-index: 1;
			width: 100%;
			height: 100%;
			top: unset;
			background-color: transparent;
			padding: 0;
			opacity: 1;
			transform: translateY(0);
			transition: none;

			&Item {
				&[data-highlited='true'] {
					width: 194px;
				}
			}
		}
	}
}
