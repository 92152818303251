.wrapper{
  display: block;

  .image{
    display: block;
    margin: 31px auto;
    width: 70%;
    max-width: 300px;
    max-height: 200px;
    object-fit: contain;
  }

  .buttons{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 35px;
  }
}