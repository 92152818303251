@use '@styles/variables' as *;

.contact {
  padding: 20px;

  form {
    margin-top: 32px;
  }

  button{
    width: 100%;
    max-width: 294px;
    margin: 0 auto;
  }

  @media screen and (min-width: $md) {
    padding: 56px 32px;
    
    form {
      margin-top: 64px;
    }
  }

  @media screen and (min-width: 1500px) {
    padding: 80px 50px;
  }
}

.done{
  display: flex;
	flex-direction: column;
	gap: 29px;
	align-items: center;
	justify-content: center;

	.title {
		font-size: 20px;
		font-weight: 600;
		line-height: 30px;
		text-align: center;
	}
}
