@use '@styles/variables' as *;

@mixin hover-effect($color) {
	background-color: $color;
	&:hover {
		background-color: darken($color, 5%);
	}
	&:active {
		background-color: darken($color, 10%);
	}
}

.button {
	display: block;
	padding: 16px 38px;
	text-align: center;
	outline: transparent;

	text-decoration: none;
	font-size: 16px;
	font-weight: 700;
	cursor: pointer;
	transition: background-color 0.24s;
	border-radius: 5em;

	&FullWidth {
		width: 100%;
	}

	&Primary {
		@include hover-effect($primary-color);
		color: $light-color;

		&:active {
			background-color: $dark-color;
		}
	}
	&Light {
		@include hover-effect($light-color);
		color: $dark-color;
	}
	&Dark {
		@include hover-effect($dark-color);
		color: $light-color;
	}

	&Outline {
		background-color: transparent;
		color: $primary-color;
	}

	&OutlineWithBorder{
		background-color: transparent;
		color: $primary-color;
		border: 1px solid $primary-color;
	}

	&Danger {
		background-color: $danger-color;
		color: $light-color;
	}

	&:disabled {
		opacity: 0.75;
		pointer-events: none;
	}
}
