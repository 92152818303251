@use '@styles/variables' as *;

.error {
	display: block;
	margin-top: 5px;
	margin-bottom: 10px;
	color: $danger-color;
	font-size: 12px;
	font-weight: 400;
	min-height: 15px;
}

.label{
  display: block;
  color: $dark-color;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 10px;
}
