@use '../../scss/variables' as *;

.content {
	min-height: calc(100vh - 64px);
	margin-top: 64px;
	background-color: $gray-color;

	@media screen and (min-width: $md) {
		min-height: 100vh;
		margin-top: 0;
		margin-left: clamp(250px, 25vw, 345px);
	}
}

.loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 9999999;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba($light-color, 0.65);
}

.test {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 9999;
	font-size: 24px;
	padding-right: 1em;
	padding-bottom: 1em;
	text-transform: uppercase;
	color: $dark-color;
	opacity: .35;
	pointer-events: none;
}
