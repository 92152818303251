.buttons{
  display: flex;
  justify-content: flex-end;
  margin-top: 31px;
  gap: 10px;
}

.metrics{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  > *{
    flex: 1 0 156px;
  }
}