@use '../../scss/variables' as *;

.header {
	padding-top: 24px;

	&Title{
		margin-bottom: 8px!important;
	}

	@media screen and (min-width: $lg) {
		.container {
			width: calc(100% - 70px);
		}
	}
}
