@use '@styles/variables' as *;

%common-btns{
	display: block;
	position: absolute;
	z-index: 999;
	top: 0;
	padding: 13px;
	background-color: transparent;
	cursor: pointer;

	svg{
		width: 22px;
		height: auto;
	}
}

.input {
	display: block;

	&Label {
		display: block;
		color: $dark-color;
		font-size: 16px;
		font-weight: 400;
		line-height: 150%;
		margin-bottom: 10px;
	}

	&Field {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 134px;
		width: 100%;
		border: 1px solid #d3d3d3;
		background: #fff;
		padding: 15px 10px 15px 22px;
		color: #959595;
		font-size: 16px;
		font-weight: 400;
		outline: transparent;
		cursor: pointer;

		input {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			opacity: 0;
			cursor: pointer;
		}

		> span {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
      align-items: center;
			gap: 14px;
			height: 100%;
			width: 100%;

			img{
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				max-height: 95%;
				object-fit: contain;
				width: auto;
				max-width: 100%;
				transform: translate(-50%, -50%);
			}
		}
	}

	&Error {
		display: block;
		margin-top: 5px;
		margin-bottom: 10px;
		color: $danger-color;
		font-size: 12px;
		font-weight: 400;
		min-height: 15px;
		opacity: 0;
		transition: opacity 0.24s;
	}

	&Remove{
		@extend %common-btns;
		right: 0;
	}
	
	&Edit{
		@extend %common-btns;
		left: 0;
	}

	&[data-status='error'] {
		.inputField {
			border: 1px solid $danger-color;
		}

		.inputError {
			opacity: 1;
		}
	}

	&[data-is-dragged='true'] {
		.inputField {
			border: 1px solid $primary-color;
		}
	}
}
