@use '@styles/variables' as *;

.wrapper {
	position: relative;
	max-width: max-content;
	z-index: 9;

	.button {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 3px;
		width: 36px;
		height: 36px;
		background-color: transparent;
		cursor: pointer;

		&Inner {
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background-color: $primary-color;
		}
	}

	.options {
		position: absolute;
		bottom: 0;
		right: 0;
		min-width: max-content;
		width: 116px;
		border-radius: 8px;
		overflow: hidden;
		filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.14));
		transform: translateY(100%);

		&Item {
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			gap: 10px;
			min-width: max-content;
			width: 100%;
			padding: 12px 16px;
			background-color: $light-color;
			font-size: 16px;
			font-weight: 500;
			line-height: 18px;
			cursor: pointer;
			transition: background-color 0.24s;

			&:hover {
				background-color: $gray-color;
			}

			&:not(:last-child) {
				border-bottom: 1px dashed $gray-color;
			}
		}
	}
}
