@use '@styles/variables' as *;

.checkbox {
	cursor: pointer;

	&Label {
		display: flex;
		position: relative;
		gap: 10px;
		font-size: 16px;
		color: #737373;

		&:before {
			content: '';
			display: block;
			width: 18px;
			min-width: 18px;
			height: 18px;
			border: 2px solid #d3d3d3;
			border-radius: 4px;
			transition: background-color 0.34s;
		}

		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 11px;
			min-width: 11px;
			height: 11px;
			top: 3.5px;
			left: 3.5px;
			background-color: $primary-color;
			border-radius: 3px;
			opacity: 0;
			transition: opacity 0.34s, transform 0.34s;
		}
	}

	&Field {
		display: none;
		width: 0;
		height: 0;
		opacity: 0;
		visibility: hidden;

		&:checked {
			+ .checkboxLabel {
				&::after {
					opacity: 1;
				}
			}
		}
	}

	&[data-is-error='true'] {
		.checkboxLabel {
			&:before {
				border: 2px solid $danger-color;
			}
		}
	}

	&[data-is-toggler='true'] {
		.checkboxLabel {
			&:before {
				content: '';
				display: block;
				width: 40px;
				min-width: 40px;
				height: 25px;
				border: none;
				border-radius: 25px;
				background-color: #d3d6e4;
			}

			&:after {
				width: 20px;
				min-width: 20px;
				height: 20px;
				top: 2.5px;
				left: 3.5px;
				background-color: $light-color;
				border-radius: 50%;
				opacity: 1;
			}
		}

		.checkboxField {
			&:checked {
				+ .checkboxLabel {
					&:before {
						background-color: #78bf21;
					}

					&::after {
						transform: translateX(70%);
					}
				}
			}
		}
	}
}
