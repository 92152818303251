@use '@styles/variables' as *;

.wrapper {
	position: relative;
	display: flex;
	align-items: center;
	gap: 22px 33px;
	background-color: #fff;
	box-shadow: 0px 9px 45px 0px #00000005;
	padding: 15px 28px;
	border-radius: 15px;
	width: 100%;
	grid-column: 1/-1;

	&Link {
		cursor: pointer;
	}

	:global {
		.options {
			position: absolute;
			top: 10px;
			right: 22px;
		}
	}

	.image {
		position: relative;
		width: 70px;
		height: 70px;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.name {
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		text-align: center;
		vertical-align: center;
		min-height: 2.5em;
		display: -webkit-box;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	&[data-is-clickable='true'] {
		cursor: pointer;
	}

	&[data-is-selected='true'] {
		box-shadow: 0 0 0 2px rgba(0, 81, 121, 1);
	}

	@media screen and (min-width: $sm) {
		flex-direction: column;
		padding: 50px 30px 20px;
		grid-column: span 6;

		.image {
			width: 90%;
			height: 140px;
		}

		.name {
			font-size: 20px;
			line-height: 30px;
		}
	}

	@media screen and (min-width: $lg) {
		grid-column: span 4;
	}

	@media screen and (min-width: $xxl) {
		grid-column: span 3;
	}
}
