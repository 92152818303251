@use '@styles/variables' as *;

.row {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 0 30px;
	margin-top: 32px;

	> * {
		flex: 1 0 300px;
	}
	@media screen and (min-width: $lg) {
		> * {
			max-width: 335px;
		}
    
		> button {
			max-width: 278px;
		}
	}
}
