@use '../../../../scss/variables' as *;

.wrapper {
	border-top: 1.5px solid #eff4f9;

	&:first-child {
		border-top: none;
	}

	.title {
		display: flex;
		justify-content: space-between;
		gap: 20px;
		padding: 20px 0;
		width: 100%;
		font-size: 14px;
		font-weight: 600;
		line-height: 24px;
		text-align: left;
		color: $dark-color;
		background-color: transparent;
		cursor: pointer;

		.expander {
			position: relative;
			width: 11px;
			height: 11px;
			margin-top: 0.5em;

			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				width: 11px;
				height: 2px;
				background: $dark-color;
				border-radius: 2px;
			}

			&:before {
				transform: translate(-50%, -50%);
			}

			&:after {
				transform: translate(-50%, -50%) rotate(90deg) scale(1);
				transform-origin: center;
				transition: transform 0.24s;
			}
		}
	}

	.description {
		width: calc(100% - 25px);
		padding: 0 0 20px;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		color: $dark-color;
	}

	&[data-is-current='true'] {
		.title {
			.expander {
				&:after {
					transform: translate(-50%, -50%) rotate(90deg) scale(0);
				}
			}
		}
	}
}
