@use '@styles/variables' as *;

.buttons{
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  margin-top: 42px;
}

.link{
  color: $primary-color;
  word-break: break-all;
}