@use '@styles/variables' as *;

.list {
	margin-top: 42px;
	:global {
		.container {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			gap: 20px;
		}
	}

	@media screen and (min-width: $md) {
			margin-top: 51px;
		}
}
