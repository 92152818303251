@use '@styles/variables' as *;

.text {
	color: $text-color;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.6em;

	a {
		font-size: inherit;
		font-weight: 600;
		text-decoration: none;
		cursor: pointer;
	}
}
