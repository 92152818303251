@use '@styles/variables' as *;
@use '@styles/mixins' as *;

.container {
	width: 100%;
	margin-top: 40px;
	margin-bottom: 40px;
	overflow-y: hidden;
	overflow-x: auto;
	@include scrollbar;
	background-color: $light-color;
	border-radius: 7px;
}

.empty {
	padding: clamp(20px, 10vh, 300px) 20px;
	font-size: 14px;
	text-align: center;
}

.wrapper {
	width: 100%;
	border-collapse: collapse;

	tbody {
		:global {
			.button {
				border: 1px solid $primary-color !important;
				padding: 8px;
				width: 100%;
				font-size: 14px;
				cursor: pointer;
			}

			.button-delete {
				cursor: pointer;
			}
		}

		img {
			width: 43px;
			height: 43px;
			object-fit: cover;
			object-position: center;
		}
	}

	:global {
		.button-delete {
			background-color: transparent;
			border: none !important;
			padding: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
