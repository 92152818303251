.button {
	display: flex;
	justify-content: flex-end;
	margin-top: 40px;
}

.title {
	display: flex;
	gap: 17px;
}

.text{
	margin-bottom: 32px;
}