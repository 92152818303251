@use '@styles/variables' as *;

.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	width: 100%;
	min-height: max-content;
	height: 100vh;

	.title {
		font-size: 100px;
		font-weight: 700;
		line-height: 132px;
		text-align: center;
		color: $primary-color;
	}

	.text {
		margin-bottom: 48px;
		font-size: 18px;
		font-weight: 400;
		line-height: 23.76px;
		text-align: center;
		color: rgba(46, 45, 38, 0.5);
		max-width: 576px;
	}

	@media screen and (min-width: $md) {
		.title {
			font-size: 200px;
			font-weight: 700;
			line-height: 264px;
		}

		.text {
      margin-bottom: 75px;
			font-size: 28px;
			font-weight: 400;
			line-height: 36.96px;
		}
	}
}
